@import '../commons/variables';
@import '../commons/placeholders';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 1px;
    animation: wow 0.5s alternate-reverse 1 forwards;
    background-color: $color-white;
    color: $color-black;
    font-weight: 700;

    svg {
        flex-shrink: 0;
        margin-left: 0.5rem;
    }

    &.wrongAnswer {
        color: $color-red;
    }

    &.rightAnswer {
        color: $color-green;
    }
}

@keyframes wow {
    to {
        box-shadow: 0 0 1rem -0.5rem $color-black;
        transform: scale(1.05);
    }
}
