@import '../commons/variables';
@import '../commons/placeholders';

.container {
    max-width: 666px;
    padding: 0 1rem;
    margin: 2rem auto;
}

.header {
    position: relative;
    margin-bottom: 1.5rem;
    color: $color-white;
}

.logo {
    max-width: 90%;
    height: auto;
}

.helpBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    height: 1.9rem;
    border-radius: 50%;
    color: $color-white;
    transition: transform 0.2s $ease-yo, opacity 0.2s ease-in-out;

    &:hover,
    &:focus {
        box-shadow: 0 0.5rem 1rem -0.25rem $color-black;
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
}

.content {
    margin: 1rem 0;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.4;

    h2 {
        margin-bottom: 1rem;
        font-size: 1.25rem;
        font-weight: 700;
    }

    p + p {
        margin-top: 0.5rem;
    }

    ul {
        padding-left: 1.5rem;
        list-style: disc;
    }

    li + li {
        margin-top: 0.5rem;
    }

    ul + p {
        margin-top: 1.5rem;
    }

    strong {
        font-weight: 700;
    }

    i {
        font-style: italic;
    }
}

.pin {
    display: inline-block;
    width: 1.5rem;
    margin: -0.5rem 0.5rem;
    vertical-align: middle;
}

.pinShape {
    stroke: $color-black;
}

.instructions {
    position: relative;
    padding: 1rem;
    margin: 2rem 0;
    background-color: $color-white;
    border-radius: 4px;
    color: $color-black;
}

.sepaqLink {
    position: absolute;
    right: 1rem;
    bottom: 1.25rem;
    width: 30%;
}

.sepaqLogo {
    width: 100%;
    height: auto;
}

.note {
    display: block;
    margin: 1.5rem 0;
    border-radius: 4px;
}

.backBtn {
    display: inline-block;
    margin: 1.5rem auto 0.5rem;
    color: $color-black;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover,
    &:focus {
        color: $color-black;
    }
}

.backArrow {
    display: inline-block;
    width: 0.75rem;
    height: auto;
    margin-right: 0.5rem;
    transform: rotate(90deg) translate(2px);
}
