/**
 * Colors
 */
$color-white: #FFE1CE;
$color-black: #212121;
$color-green: #55BA47;
$color-red: #F76C20;

/**
  * Fonts
  */
// $font-text: 'Inter', sans-serif;
$font-text: 'Avant Garde', serif;
$font-title: 'Helvetica', sans-serif;
$font-controls: 'Avant Garde', sans-serif;

/**
  * Font size
  */
$font-size-small: 14px;
$font-size-normal: 16px;
$font-size-medium: 24px;
$font-size-large: 36px;
$font-size-extra-large: 46px;

/**
  * Screen size
  */
$small-screen: 500px;
$medium-screen: 768px;
$large-screen: 1100px;
$larger-screen: 1400px;

/**
  * Media queries
  */
$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-larger-screen: 'only screen and (min-width: #{$larger-screen})';

/**
  * Controls
  */
$controls-height: 20%;

/**
  * Navigation
  */
$button-size: 60px;
$button-played-size: 40px;

/**
 * Easings
 */
$ease-yo: cubic-bezier(.65,.25,0,1.75);
$ease-biggie: cubic-bezier(.4,0,0,2);
