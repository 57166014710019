@import '../commons/variables';
@import '../commons/placeholders';

.title {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
}

.intro{
    margin-bottom: 2rem;
}

.searchForm {
    position: relative;
    display: flex;
}

.searchContainer {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    background-color: $color-white;
    color: $color-green;
}

.searchIcon {
    position: absolute;
    width: 1.25rem;
    height: auto;
    margin-left: 0.75rem;
    transition: transform 0.3s $ease-yo;

    &.isSearching {
        transform: translateY(-5%) rotate(90deg) scale(1.2);
    }
}

.searchField {
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    transition: color 0.1s ease-in-out;
}

.searchInput,
.searchChoice {
    width: 100%;
    height: 3rem;
    padding: 1rem 0.75rem;
    border: 0;
    background-color: transparent;
    color: $color-green;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25;
    transition: color 0.2s $ease-yo;

    @media (hover: hover) {
        &:hover {
            color: $color-black;

            .searchClear {
                display: inline-block;
            }
        }
    }
}

.searchInput {
    padding: 1rem 0.75rem 1rem 3rem;
}

.searchInput::placeholder {
    color: $color-green;
    font-style: italic;
    transition: color 0.1s ease-in-out;
}

@media (hover: hover) {
    .searchField:hover,
    .searchField:focus-within {
        color: $color-black;

        .searchInput::placeholder {
            color: $color-black;
        }
    }
}

.searchChoice {
    display: flex;
    height: auto;
    align-items: center;
    animation: cool 0.3s $ease-yo 1 forwards;
    font-size: 1.15rem;
    font-weight: 700;
    text-align: left;
    transform: translateY(35%);
}

.searchChoiceTitle {
    flex-grow: 1;
}

@keyframes cool {
    to {
        transform: translateY(0);
    }
}

.searchClear {
    display: none;
    width: 1.4rem;
    height: auto;
    color: $color-black;
    transition: transform 0.2s $ease-yo;
}

.searchSubmit {
    padding: 0.5rem;
    margin-left: 3px;
    background-color: $color-black;
    color: $color-white;
    cursor: pointer;
    font-weight: 500;
    transition:
        transform 0.2s $ease-yo,
        opacity 0.1s ease-in-out;

    &[disabled] {
        background-color: rgba($color-black, 0.5);
        opacity: 0.75;
    }

    @media (hover: hover) {
        &:hover,
        &:focus {
            background-color: $color-white;
            box-shadow: 0 0.5rem 1rem -0.25rem $color-black;
            color: $color-black;
            transform: scale(1.1);
        }

        &:active {
            transform: scale(0.9);
        }
    }
}

.searchList {
    width: 100%;
    padding-bottom: 1rem;
    margin-top: 3px;
}

.searchSuggestionBtn {
    z-index: 1;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 0.75rem;
    border-bottom: 1px solid $color-green;
    background-color: $color-white;
    color: $color-green;
    font-weight: 500;
    text-align: left;
    transition: transform 0.2s $ease-yo;

    @media (hover: hover) {
        &:hover,
        &:focus {
            z-index: 2;
            box-shadow: 0 0.25rem 1rem -0.5rem $color-black;
            transform: scale(1.02);

            .pin {
                transform: scale(1.2);
            }
        }

        &:active {
            transform: scale(0.98);
            transition: transform 0.1s $ease-yo;

            .pin {
                transform: scale(0.9);
            }
        }
    }
}

.pin {
    width: 1rem;
    height: auto;
    flex-shrink: 0;
    margin-right: 1rem;
    fill: $color-green;
    stroke: $color-green;
    transition: transform 0.4s $ease-yo;
}

.searchEmpty {
    padding: 1rem;
    background-color: $color-white;
    color: $color-green;
    font-weight: 500;
}

.answerHeading {
    margin: 0 0 1.5rem;
    font-size: 1.25rem;
    font-weight: 700;
}

.rightAnswerDescription {
    padding: 1rem;
    margin-top: 1px;
    background-color: $color-white;
    color: $color-green;
    font-weight: 500;
    line-height: 1.3;

    p + p {
        margin-top: 1rem;
    }
}

.rightAnswerMoreLink {
    display: inline-block;
    margin-top: 1rem;
    color: $color-green;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover,
    &:focus {
        color: $color-black;
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.shareBtn {
    position: relative;
    overflow: hidden;
    padding: 0;
    border: 1px solid $color-white;
    background-color: $color-black;
    color: $color-white;
    font-weight: 500;
    transition: transform 0.2s $ease-yo;

    div {
        padding: 1rem 0.75rem;
        text-align: center;
        transition: transform 0.3s $ease-yo;
        user-select: none;
    }

    @media (hover: hover) {
        &:hover,
        &:focus {
            box-shadow: 0 0.25rem 1rem -0.5rem $color-black;
            transform: scale(1.02);

            .pin {
                transform: scale(1.02) rotate(-15deg);
            }
        }
    }

    &:active {
        transform: scale(0.95);
    }
}

.shareLabel {
    .wasCopied & {
        transform: translateY(-100%);
    }
}

.shareCopied {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);

    svg {
        display: inline-block;
        width: 1.25rem;
        height: auto;
        margin-left: 0.25rem;
        vertical-align: middle;

        path {
            fill: currentcolor;
        }
    }

    .wasCopied & {
        transform: translateY(0%);
    }
}

.shareMessage {
    display: inline-block;
    width: auto;
    padding: 1rem;
    margin: 1rem 0;
    background-color: $color-white;
    border-radius: 4px;
    color: $color-green;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;
    transition: transform 0.2s $ease-yo;

    @media (hover: hover) {
        &:hover,
        &:focus {
            transform: scale(1.05);
        }

        &:active {
            transform: scale(0.98);
            transition: transform 0.1s $ease-yo;

            .pin {
                transform: scale(0.9);
            }
        }
    }
}

.nextChallengeLink {
    display: inline-flex;
    align-items: center;
    color: $color-white;
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover,
    &:focus {
        color: $color-black;
    }
}

.nextChallengeLinkIcon {
    width: 0.75rem;
    height: auto;
    margin-left: 0.25rem;
}
