@import '../commons/variables';
@import '../commons/placeholders';

.pageTitle {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
}

.result {
    padding: 1rem;
    margin: 2rem 0;
    background-color: $color-white;
    border-radius: 4px;
    color: $color-black;
    text-align: center;
}

.results {
    .heading {
        font-size: 1.75rem;
    }

    p a {
        color: $color-white;
        text-underline-offset: 2px;

        &:hover,
        &:focus {
            color: $color-black;
        }
    }
}

.title {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 700;
}

.score {
    font-size: 2rem;
    letter-spacing: 0.5rem;
}
