/**
 * Dehors
 */
@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    src: url('../../fonts/avant-garde/avantgarde.woff2') format('woff2'),
        url('../../fonts/avant-garde/avantgarde.woff') format('woff'),
        url('../../fonts/avant-garde/avantgarde.otf') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-weight: 500;
    src: url('../../fonts/avant-garde/avantgarde-medium.woff2') format('woff2'),
        url('../../fonts/avant-garde/avantgarde-medium.woff') format('woff'),
        url('../../fonts/avant-garde/avantgarde-medium.otf') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-weight: 700;
    src: url('../../fonts/avant-garde/avantgarde-bold.woff2') format('woff2'),
        url('../../fonts/avant-garde/avantgarde-bold.woff') format('woff'),
        url('../../fonts/avant-garde/avantgarde-bold.otf') format('woff');
}
