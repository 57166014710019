@import 'commons/variables';
@import 'commons/fonts';
@import 'commons/reset';

body {
    min-height: 0;
    padding-bottom: 5rem;
    background: $color-green;
    color: $color-white;
    font-family: $font-text;
}

a,
button {
    -webkit-tap-highlight-color: rgb(0 0 0 / 0);
}

a,
button,
input,
textarea {
    &:focus-visible {
        outline: 3px solid $color-black;
        outline-offset: 3px;
    }
}
