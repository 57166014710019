@import '../commons/variables';
@import '../commons/placeholders';

.container {
    display: flex;
    margin: -2rem 0 1rem 0.5rem;
}

.btn {
    color: rgba($color-black, 0.25);
    font-weight: 100;
    transition: transform 0.2s $ease-yo;

    &.isActive,
    &.isCurrent {
        color: $color-black;
        font-weight: 500;
    }

    @media (hover: hover) {
        &:hover,
        &:focus {
            z-index: 2;
            transform: scale(1.2);
        }

        &:active {
            transform: scale(0.98);
            transition: transform 0.1s $ease-yo;

            .pin {
                transform: scale(0.9);
            }
        }
    }
}

.pin {
    display: block;
    margin-right: 0.25rem;
    color: currentcolor;
}
