@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    width: 2rem;
    font-weight: 500;
    transform-origin: center center;
    user-select: none;
}

.step {
    position: absolute;
    top: 17.5%;
    width: 100%;
    color: currentcolor;
    font-size: 1rem;
    text-align: center;
}

.pin {
    width: 100%;
    height: auto;
}

.right,
.wrong {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 65%;
    height: auto;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center center;
    transition: transform 0.3s $ease-biggie;
}

.rightAnswer {
    animation: wow 0.3s alternate-reverse 1 forwards;

    .right {
        transform: translate(-50%, -50%) scale(1);
    }
}

.wrongAnswer {
    animation: wow 0.3s alternate-reverse 1 forwards;

    .wrong {
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes wow {
    to {
        transform: scale(1.5) translateY(-15%);
    }
}
