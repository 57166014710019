@import '../commons/variables';
@import '../commons/placeholders';

.frame {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 62.5%;
    border: 1px solid $color-white;
    background-color: $color-white;
}

.slides {
    display: flex;
}

.slide {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    transform-origin: center center;
}

.image {
    width: 100%;
    -webkit-user-drag: none;
    user-select: none;
}

.credits {
    position: absolute;
    right: 0.5rem;
    bottom: 0;
    max-width: 50%;
    padding: 0 0.25rem;
    background-color: $color-white;
    border-radius: 4px 4px 0 0;
    color: $color-black;
    font-size: 0.65rem;
}
