@import '../commons/variables';
@import '../commons/placeholders';

.container {
    margin-top: 1rem;
}

.choice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0.75rem;
    margin-top: 1px;
    background-color: $color-white;
    color: $color-red;
    font-weight: 700;

    span {
        display: inline-block;
        padding-right: 1.5rem;
    }

    &.rightAnswer {
        color: $color-green;
    }
}

.choiceEmpty {
    height: 3rem;
}
